import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import iconBaths from '../../../images/icons/icon-bath.svg';
import DollarIcon from '../../../images/icons/icon-Dollar.svg';
import iconBeds from '../../../images/icons/icon-beds.svg';
import iconBack from '../../../images/icons/icon-chevron-right.svg';
import iconClose from '../../../images/icons/icon-close.svg';
import iconSqFeet from '../../../images/icons/icon-square-feet.svg';
import { IRootState } from '../../../redux/rootReducer';
import styles from './BookAppointment.module.css';
import Confirm from './Confirm/Confirm';
import ReserveCalendar from './ReserveCalendar/ReserveCalendar';
import TimeSlots from './TimeSlots/TimeSlots';

interface BookAppointmentProps {
    setOpen: Dispatch<SetStateAction<boolean>>;
    appointmentDate: string;
    appoinmentStartTime: string;
    appointmentCloseTime: string;
    showPrice?: boolean
}

export interface SlotsProps {
    label: string;
    value: string;
    isDisabled?: boolean;
}

type LocationState = { appointmentId: string };

const BookAppointment = ({ setOpen, appoinmentStartTime, appointmentDate, appointmentCloseTime, showPrice = true }: BookAppointmentProps) => {
    const { appointmentId } = (useLocation().state || '') as LocationState;

    const search = useLocation().search;

    const [showCalendar, setShowCalendar] = useState(true);
    const [showTime, setShowTime] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [timings, setTimings] = useState({ startTime: '', endTime: '' });
    const [availabilityId, setAvailabilityId] = useState([]);
    const [availableIds, setAvailableIds] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [slots, setSlots] = useState<SlotsProps[]>([]);
    const [selectedSlots, setSelectedSlots] = useState('');
    const [lockerInfo, setLockerInfo] = useState();

    const unit = useSelector((state: IRootState) => state.unitReducer.unit.data.units);

    const appointmentData = useSelector((state: IRootState) => state?.appointmentReducer?.appointment?.data);

    useEffect(() => {
        if (search) {
            let [modifiedAppointmentDate] = search.replace(/\?/g, '').replace(/&/g, '').split('_');
            modifiedAppointmentDate = moment(modifiedAppointmentDate).format('YYYY-MM-DD');
            setSelectedDate(moment(modifiedAppointmentDate).format());
        }
    }, [search]);

    const handleBack = () => {
        if (showTime) {
            setShowTime(false);
            setShowCalendar(true);
            setOpen(false);
            setSelectedDate(selectedDate);
            setSelectedSlots(selectedSlots);
        }
        if (showConfirm) {
            setShowConfirm(false);
            setShowTime(true);
            setShowCalendar(true);
            setSelectedDate(selectedDate);
            setSelectedSlots(selectedSlots);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.flex}>
                    {!showCalendar && (
                        <div className={styles.back} onClick={handleBack}>
                            <img src={iconBack} alt="back" />
                        </div>
                    )}
                    <div className={`${styles.headerContent} ${showConfirm ? styles.withConfirm : ''}`}>
                        <div className={styles.wrapper}>
                            <h3 className={styles.headerTitle}>
                                {unit?.unitYardiId ? unit?.unitYardiId : unit?.name} {unit?.nickName && <span> "{unit?.nickName}" </span>}
                            </h3>
                        </div>
                        <div className={styles.facilities}>
                            <span className={styles.facility}>
                                <img src={iconSqFeet} alt="" />
                                {unit?.size} Sq Ft
                            </span>
                            <span className={styles.facility}>
                                <img src={iconBeds} alt="" />

                                <span>
                                    {+unit?.rooms ? unit?.rooms : ''} {!+unit?.rooms ? 'Studio' : +unit.rooms === 1 ? 'Bed' : 'Beds'}
                                </span>
                            </span>
                            <span className={styles.facility}>
                                <img src={iconBaths} alt="" />
                                {(+unit?.baths || +unit?.baths === 0) && (
                                    <span>{`${+unit?.baths} ${+unit?.baths === 1 ? 'Bath' : 'Baths'}`}</span>
                                )}
                            </span>
                            {!!showPrice && <span className={styles.facility}>
                                <img src={DollarIcon} alt="" />
                                {unit?.price ? unit?.price : '0'}*
                            </span>}
                        </div>
                    </div>
                </div>
                <span className={styles.closeBtn} onClick={() => setOpen(false)}>
                    <img src={iconClose} alt="Close" />
                </span>
            </div>

            {showCalendar && (
                <ReserveCalendar
                    selectedDate={selectedDate}
                    setShowCalendar={setShowCalendar}
                    setShowTime={setShowTime}
                    setAvailabilityId={setAvailabilityId}
                    setSelectedDate={setSelectedDate}
                    availabilities={unit?.availabilities}
                    setShowConfirm={setShowConfirm}
                    slots={slots}
                    setSelectedSlots={setSelectedSlots}
                    selectedSlots={selectedSlots}
                    availabilityId={availabilityId}
                    setAvailableIds={setAvailableIds}
                    appoinmentStartTime={appoinmentStartTime}
                    appointmentCloseTime={appointmentCloseTime}
                    appointmentDate={appointmentDate}
                />
            )}
            {showTime && (
                <TimeSlots
                    unit={unit}
                    availabilityId={availabilityId}
                    timings={timings}
                    setTimings={setTimings}
                    selectedDate={selectedDate}
                    setShowTime={setShowTime}
                    setShowConfirm={setShowConfirm}
                    setAvailableIds={setAvailableIds}
                    setSlots={setSlots}
                    setLockerInfo={setLockerInfo}
                    lockerInfo={lockerInfo}
                />
            )}
            {showConfirm && (
                <Confirm
                    selectedSlots={selectedSlots}
                    timings={timings}
                    availabilities={availableIds}
                    selectedDate={selectedDate}
                    unit={unit}
                    lockerInfo={lockerInfo}
                    appointmentId={appointmentId}
                />
            )}
        </div>
    );
};

export default BookAppointment;
